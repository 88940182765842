import React, { useEffect, useState } from 'react';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { StaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { css } from '@emotion/react';
import theme from '../../theme';

const Dot = styled.div`
  border-radius: 50%;
  width: 14px;
  height: 14px;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  background-color: ${({ value, id, theme }) =>
    value === id ? theme.colors.blue : theme.colors.white};
`;

const FeatureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.spacing[4]};
  p {
    color: ${({ theme }) => theme.colors.text};
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes[4]};
    margin: 0;
  }
  a {
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.text};
    text-decoration: none;
    :visited {
      color: ${({ theme }) => theme.colors.text};
    }
  }
  strong {
    color: ${({ theme }) => theme.colors.red};
    font-weight: 400;
  }
  h2 {
    color: ${({ theme }) => theme.colors.red};
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes[6]};
    margin-bottom: ${({ theme }) => theme.spacing[6]};
  }
`;

const FeaturedText = styled.div`
  flex: 0 1 468px;
  box-sizing: border-box;
  margin-right: 32px;
`;

const QuestionsCarousel = () => {
  const [value, setValue] = useState(0);

  const onChange = (value) => {
    setValue(value);
  };

  const [rendered, setRendered] = useState(false);

  useEffect(() => setRendered(true), [setRendered])

  return (
    <StaticQuery
      query={graphql`
        query QuestionsCarousel {
          image1: file(relativePath: { eq: "multiple-choice-questions.png" }) {
            ...questionCarouselImage
          }
          image2: file(relativePath: { eq: "q-question-mc2@2x.png" }) {
            ...questionCarouselImage
          }
          image3: file(
            relativePath: { eq: "03q-question-shortanswer@2x.png" }
          ) {
            ...questionCarouselImage
          }
          image4: file(
            relativePath: { eq: "04q-question-wordsearch@2x@2x.png" }
          ) {
            ...questionCarouselImage
          }
          image5: file(relativePath: { eq: "05q-cloze@2x@2x.png" }) {
            ...questionCarouselImage
          }
          image6: file(relativePath: { eq: "06q-essay@2x@2x.png" }) {
            ...questionCarouselImage
          }
          image7: file(relativePath: { eq: "Question image@2x.png" }) {
            ...questionCarouselImage
          }
          image8: file(relativePath: { eq: "08q-matching@2x@2x.png" }) {
            ...questionCarouselImage
          }
          image9: file(relativePath: { eq: "09q-memory@2x@2x.png" }) {
            ...questionCarouselImage
          }
          image10: file(
            relativePath: { eq: "10q-question-orderquestion@3x@2x.png" }
          ) {
            ...questionCarouselImage
          }
          image11: file(relativePath: { eq: "11q-jumble@2x@2x.png" }) {
            ...questionCarouselImage
          }
          image12: file(relativePath: { eq: "12q-keyboard@2x@2x.png" }) {
            ...questionCarouselImage
          }
          image13: file(relativePath: { eq: "13q-clapback@2x@2x.png" }) {
            ...questionCarouselImage
          }
          image14: file(relativePath: { eq: "14q-freehand@2x@2x.png" }) {
            ...questionCarouselImage
          }
        }
      `}
      // image={data.image1.childImageSharp.gatsbyImageData}
      render={(data) => (
        <div
          css={css`
            padding: ${theme.spacing[20]} 0;
          `}
        >
          { rendered && (
          <Carousel value={value} onChange={onChange}>
            <FeatureContainer>
              <FeaturedText>
                <h2>Multiple Choice Questions</h2>
                <p>
                  Create questions with two, three, or four possible answers
                </p>
              </FeaturedText>
              <GatsbyImage
                image={data.image1.childImageSharp.gatsbyImageData}
                alt="Multiple Choice Questions"
              />
            </FeatureContainer>
            <FeatureContainer>
              <FeaturedText>
                <h2>Multiple Choice Questions</h2>
                <p>
                  Questions and answers can both contain images, audio, video,
                  or text.
                </p>
              </FeaturedText>
              <GatsbyImage
                image={data.image2.childImageSharp.gatsbyImageData}
                alt="Multiple Choice Questions"
              />
            </FeatureContainer>
            <FeatureContainer>
              <FeaturedText>
                <h2>Short Answer Questions</h2>
                <p>
                  Create short-answer questions. Questions can contain images,
                  audio, video, or text.
                </p>
              </FeaturedText>
              <GatsbyImage
                image={data.image3.childImageSharp.gatsbyImageData}
                alt="Short Answer Questions"
              />
            </FeatureContainer>
            <FeatureContainer>
              <FeaturedText>
                <h2>Word Search Questions</h2>
                <p>
                  Create your own word search puzzle! You can use text or images
                  for clues.
                </p>
              </FeaturedText>
              <GatsbyImage
                image={data.image4.childImageSharp.gatsbyImageData}
                alt="Word Search Questions"
              />
            </FeatureContainer>
            <FeatureContainer>
              <FeaturedText>
                <h2>Fill in the Blank Questions</h2>
                <p>
                  Create Fill in the Blank sentences (also known as "cloze"
                  tests). Use as many embedded answers as you need.
                </p>
              </FeaturedText>
              <GatsbyImage
                image={data.image5.childImageSharp.gatsbyImageData}
                alt="Fill in the Blank Questions"
              />
            </FeatureContainer>
            <FeatureContainer>
              <FeaturedText>
                <h2>Long Answer Questions</h2>
                <p>
                  Challenge your students with thoughtful long-form written
                  responses.
                </p>
              </FeaturedText>
              <GatsbyImage
                image={data.image6.childImageSharp.gatsbyImageData}
                alt="Long Answer Questions"
              />
            </FeatureContainer>
            <FeatureContainer>
              <FeaturedText>
                <h2>Map Questions</h2>
                <p>Use your own map and pin images to create a map question.</p>
              </FeaturedText>
              <GatsbyImage
                image={data.image7.childImageSharp.gatsbyImageData}
                alt="Map Questions"
              />
            </FeatureContainer>
            <FeatureContainer>
              <FeaturedText>
                <h2>Matching Questions</h2>
                <p>
                  Pair an item on the left with an item on the right. You can
                  use text, audio, or images for items.
                </p>
              </FeaturedText>
              <GatsbyImage
                image={data.image8.childImageSharp.gatsbyImageData}
                alt="Matching Questions"
              />
            </FeatureContainer>
            <FeatureContainer>
              <FeaturedText>
                <h2>Memory Game</h2>
                <p>
                  Flip the cards to find matches. You can use text or images for
                  items.
                </p>
              </FeaturedText>
              <GatsbyImage
                image={data.image9.childImageSharp.gatsbyImageData}
                alt="Memory Game"
              />
            </FeatureContainer>
            <FeatureContainer>
              <FeaturedText>
                <h2>Order Questions</h2>
                <p>
                  Arrange items in the correct order. You can use text or images
                  for items.
                </p>
              </FeaturedText>
              <GatsbyImage
                image={data.image10.childImageSharp.gatsbyImageData}
                alt="Order Questions"
              />
            </FeatureContainer>
            <FeatureContainer>
              <FeaturedText>
                <h2>Jumble Questions</h2>
                <p>Unscramble single words or multiple phrases</p>
              </FeaturedText>
              <GatsbyImage
                image={data.image11.childImageSharp.gatsbyImageData}
                alt="Jumble Questions"
              />
            </FeatureContainer>
            <FeatureContainer>
              <FeaturedText>
                <h2>Keyboard Questions</h2>
                <p>
                  Keyboard questions contain two formats: <br />
                  sight reading question and audio clip (listen and play)
                </p>
              </FeaturedText>
              <GatsbyImage
                image={data.image12.childImageSharp.gatsbyImageData}
                alt="Keyboard Questions"
              />
            </FeatureContainer>
            <FeatureContainer>
              <FeaturedText>
                <h2>Clap-back Questions</h2>
                <p>listen to the clip and clap it back.</p>
              </FeaturedText>
              <GatsbyImage
                image={data.image13.childImageSharp.gatsbyImageData}
                alt="Clab-back Questions"
              />
            </FeatureContainer>
            <FeatureContainer>
              <FeaturedText>
                <h2>Free-hand Drawing</h2>
                <p>
                  Draw out your answers using various pen widths and colors.
                </p>
              </FeaturedText>
              <GatsbyImage
                image={data.image14.childImageSharp.gatsbyImageData}
                alt="Clab-back Questions"
              />
            </FeatureContainer>
          </Carousel>
          ) 
          }
          <Dots
            css={css`
              button {
                background-color: transparent;
              }
            `}
            value={value}
            onChange={onChange}
            thumbnails={[
              <Dot key={0} id={0} value={value} />,
              <Dot key={1} id={1} value={value} />,
              <Dot key={2} id={2} value={value} />,
              <Dot key={3} id={3} value={value} />,
              <Dot key={4} id={4} value={value} />,
              <Dot key={5} id={5} value={value} />,
              <Dot key={6} id={6} value={value} />,
              <Dot key={7} id={7} value={value} />,
              <Dot key={8} id={8} value={value} />,
              <Dot key={9} id={9} value={value} />,
              <Dot key={10} id={10} value={value} />,
              <Dot key={11} id={11} value={value} />,
              <Dot key={12} id={12} value={value} />,
              <Dot key={13} id={13} value={value} />,
            ]}
          />
        </div>
      )}
    />
  );
};

export const graphicImage = graphql`
  fragment questionCarouselImage on File {
    childImageSharp {
      gatsbyImageData(width: 468)
    }
  }
`;

export default QuestionsCarousel;
