import React from 'react';
import { css } from '@emotion/react';
import { EMOTION_BREAKPOINTS } from '../../constants/breakpoints';
import theme from '../../theme';
import { H2, H1, P, highlight } from '.';

const QuestionTypes = () => (
  <div
    css={css`
      padding-bottom: ${theme.spacing[13]};
    `}
  >
    <div
      css={css`
        color: ${theme.colors.text};
        margin-bottom: ${theme.spacing[7]};
        text-align: center;
        ${EMOTION_BREAKPOINTS.sm} {
          margin-bottom: 0;
        }
      `}
    >
      <H1>Question Types</H1>
      <P>
        More than <em css={highlight}> 15 different question types </em>
        Create the perfect <em css={highlight}> quiz for any subject </em>{' '}
        <br />
        From simple <em css={highlight}> multiple choice questions</em> to more
        complex
        <em css={highlight}> drawing </em> or{' '}
        <em css={highlight}> music questions </em>
      </P>
    </div>
    <div
      css={css`
        ${EMOTION_BREAKPOINTS.sm} {
          display: none;
        }
      `}
    >
      <H2>Multiple Choices Questions</H2>
      <P>Create questions with two, three, or four possible answers</P>
    </div>
  </div>
);

export default QuestionTypes;
