import React from 'react';
import { css } from '@emotion/react';
import { EMOTION_BREAKPOINTS } from '../../constants/breakpoints';
import theme from '../../theme';
import { H1, highlight, P } from '.';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, StaticQuery } from 'gatsby';

const EmailResults = () => (
  <div
    css={css`
      padding-bottom: ${theme.spacing[20]};
    `}
  >
    <div
      css={css`
        color: ${theme.colors.text};
        margin-bottom: ${theme.spacing[7]};
        text-align: center;
        ${EMOTION_BREAKPOINTS.sm} {
          margin-bottom: 0;
          padding-bottom: ${theme.spacing[10]};
        }
      `}
    >
      <H1>View and Email Results</H1>
      <P
        css={css`
          max-width: 800px;
          margin: 0 auto;
        `}
      >
        As your students answer the test questions
        <em css={highlight}> graphs and charts</em> will automatically update
        themselves.
      </P>
    </div>
    <div
      css={css`
        display: flex;
        align-items: center;
        ${EMOTION_BREAKPOINTS.sm} {
          justify-content: space-between;
          padding-left: ${theme.spacing[10]};
          padding-right: ${theme.spacing[10]};
        }
      `}
    >
      <StaticQuery
        query={graphql`
          query EmailResults {
            image: file(relativePath: { eq: "view-email.png" }) {
              childImageSharp {
                gatsbyImageData(width: 472)
              }
            }
          }
        `}
        render={(data) => (
          <GatsbyImage
            image={data.image.childImageSharp.gatsbyImageData}
            alt="adapative testing"
          />
        )}
      />
      <div
        css={css`
          font-size: 14px;
          flex-basis: 268px;
          ${EMOTION_BREAKPOINTS.sm} {
            font-size: ${theme.fontSizes[4]};
          }
          max-width: 470px;
          ul {
            list-style: none;
            margin: 0;
            padding-left: 0;
          }
          li {
            padding-top: ${theme.spacing[4]};
            color: ${theme.colors.red};
            margin: 0;
            font-weight: 400;
          }
        `}
      >
        <P as="div">From your iPad:</P>
        <ul>
          <li>Compare students</li>
          <li>View test results</li>
          <li>View email results</li>
        </ul>
      </div>
    </div>
  </div>
);

export default EmailResults;
