import React from 'react';
import { css } from '@emotion/react';
import { EMOTION_BREAKPOINTS } from '../../constants/breakpoints';
import theme from '../../theme';
import { H1, highlight, P } from '.';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, StaticQuery } from 'gatsby';

const AdaptiveTesting = () => (
  <div
    css={css`
      padding-bottom: ${theme.spacing[20]};
    `}
  >
    <div
      css={css`
        color: ${theme.colors.text};
        margin-bottom: ${theme.spacing[7]};
        text-align: center;
        ${EMOTION_BREAKPOINTS.sm} {
          margin-bottom: 0;
          padding-bottom: ${theme.spacing[10]};
        }
      `}
    >
      <H1>Computerized Adaptive Testing</H1>
      <P>
        It's really easy to create
        <em css={highlight}> Computerized Adaptive Tests (CAT)</em> as well!
      </P>
    </div>
    <div
      css={css`
        display: flex;
        align-items: center;
        ${EMOTION_BREAKPOINTS.sm} {
          justify-content: space-between;
          padding-left: ${theme.spacing[10]};
          padding-right: ${theme.spacing[10]};
        }
      `}
    >
      <P
        css={css`
          max-width: 470px;
        `}
      >
        Just assign a <em css={highlight}>difficulty level</em> to each
        question, and specify the
        <em css={highlight}> 'adaptive'</em> assessment type and you are on your
        way to a whole new world of assessments!
      </P>
      <StaticQuery
        query={graphql`
          query AdaptiveTesting {
            image: file(relativePath: { eq: "adaptive-tests.png" }) {
              childImageSharp {
                gatsbyImageData(width: 472)
              }
            }
          }
        `}
        render={(data) => (
          <GatsbyImage
            image={data.image.childImageSharp.gatsbyImageData}
            alt="adapative testing"
          />
        )}
      />
    </div>
  </div>
);

export default AdaptiveTesting;
