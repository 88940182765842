import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {EMOTION_BREAKPOINTS} from '../../constants/breakpoints';
import theme from '../../theme';

export const highlight = css`
  color: ${theme.colors.red};
  font-weight: 400;
  font-style: normal;
`;

export const P = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  ${EMOTION_BREAKPOINTS.sm} {
    font-size: ${theme.fontSizes[4]};
  }
`;

export const H1 = styled.h1`
  margin: 0;
  font-size: ${theme.spacing[4]};
  font-weight: 400;
  padding-bottom: ${theme.spacing[3]};
  ${EMOTION_BREAKPOINTS.sm} {
    padding-bottom: ${theme.spacing[5]};
    font-size: ${theme.fontSizes[6]};
  }
`;

export const H2 = styled.h2`
  ${H1};
  font-size: ${theme.fontSizes[5]};
`;
