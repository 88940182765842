import React from 'react';
import Masthead from '../components/Features/Masthead';
import QuestionTypes from '../components/Features/QuestionTypes';
import Pricing from '../components/Platform/PricingSection';
import { Container, SectionTitle } from '../components/UI';
import Layout from '../components/layout';
import Seo from '../components/seo';
import AdaptiveTesting from '../components/Features/AdaptiveTesting';
import EmailResults from '../components/Features/EmailResults';
import QuestionsCarousel from '../components/Features/QuestionsCarousel';

const Features = () => {
  return (
    <Layout>
      <Seo title="features" />
      <Container as="section">
        <Masthead />
        <QuestionTypes />
        <QuestionsCarousel />
        <EmailResults />
        <AdaptiveTesting />
        <Container>
          <SectionTitle>Plans</SectionTitle>
          <Pricing />
        </Container>
      </Container>
    </Layout>
  );
};
export default Features;
